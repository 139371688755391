:root {
    --padding: 2%; /* Must be a percentage value if you want IE10 compatability */
    --margin: 1%; /* Must be a percentage value if you want IE10 compatability */
    --vertical-padding: 2em; /* must NOT be a percentage value */
    --vertical-margin: 2em; /* must NOT be a percentage value */
    --breakpoint-m: 1100px;
    --breakpoint-s: 800px;
    --breakpoint-xs: 550px;
    --breakpoint-menu: 1100px;
    --container-max-width: 1100px;
    --fixed-header-height: 80px;
 }

@import "/cssmodules/normalize.css";
    @import "/cssmodules/leaflet2.css";
    @import "/cssmodules/basic_styling.css";
@import "/theme/intendit/css/modules/flex_grid.css";
@import "/cssmodules/slick.css";
@import "/cssmodules/slick-theme.css";
@import "/cssmodules/menu.css";
@import "/cssmodules/font-awesome.css";
@import "/cssmodules/magnific-popup.css";
@import "/fontmodules/Hind.css";
@import "/theme/intendit/css/modules/hoverboxar.css";   

/* 
@import "/cssmodules/animate.css";
*/
 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------GENERAL-----------------------------------------------------------------------*/
body {font-weight: 300; 
      color: #404040;
      line-height:1.4em;
      font-family: 'Hind', sans-serif;
      /*background: url('/thumbs/1920x1080r/theme/intendit/graphics/top.jpg') center top/cover no-repeat fixed;*/  }
*{ min-width: 0px;}

/*---------------------------------------------------------------DIVERSE "WRAPPERS"------------------------------------------------------------*/
.wrapper { min-height: 100vh; position:relative ; margin-top: 80px; }  
.startsida .wrapper {min-height:10px; }
.container {width:100%; margin: 0 auto; }
.container.sml {max-width: 900px;}
.container.lrg {max-width: 1300px;}
.sida {padding: 40px 0 50px; } 

/*---------------------------------------------------------------BASIC ELEMENTS----------------------------------------------------------------*/
a, a:hover {color:#404040; font-weight:300; text-decoration: none;}
p {margin:3px 0 20px;}
strong {font-weight:500;}
iframe {max-width:100%;}

/*-------------------images--------------------*/
img {max-width:100%; height:auto;}
.bild {float:right; width:360px;  max-width:40%; height:auto;  margin: 0 0 30px 30px; overflow:hidden;}
.bild span {font-style:italic; color:#606060;} /*bildtext inne på sidor*/

img.box_image {width:100%; flex:none; max-width:100%; display:block;  } /*säkra vyn i IE*/
.bild img, .galleri_bild img {width:100%; height:auto; display:block;  } /*säkra vyn i IE*/

/*-------------------listor--------------------*/
.sida ul {list-style:disc; padding: 0 0 0 15px; }
.sida ul li {margin-bottom:3px;  }
/*.sida ul {list-style:none; padding: 0; margin: 10px 0;}
.sida ul li:before {content: "\f00c"; margin:0 10px 0 0; font-family: FontAwesome;}*/

/*------------readmore & buttons----------------*/
.button, .readmore, .boltforms-row button {background: rgba(0, 0, 0, 0.15); padding: 6px 0 8px; display:block; margin:0; text-align: center; border-radius:2px; line-height: 1.2em; border:none; transition:background 0.5s ease-in 0s;}
.readmore { width:100px;} .button, .boltforms-row button  { width:100%;}  
.button:hover, .readmore:hover, .boltforms-row button:hover {background:rgba(0, 0, 0, 0.3); transition:background 0.5s ease-in 0s;}
.boltforms-row button, .boltforms-row button:hover { color:#fff; }
button.slick-arrow {background:none;}


/*-----------------ikoner-----------------------*/
.fa.round {border-radius: 50%; padding: 11px 0 0; text-align: center; width: 45px; height: 45px; font-size:1.5em;background:rgba(0, 0, 0, 0.15);}
.fa.circle {border-radius: 50%; padding: 12px 0 0; text-align:center; width: 50px; height: 50px; font-size:1.5em; border: 1px solid #fff; color:#fff; display:block;}

.toptext .fa.round { padding: 13px 0 0; margin: 0 0 6px 6px; background: rgba(95, 161, 28, 0.7); color: #ffffff; height: 50px; width: 50px; /*clear:both; display:block;*/}
footer .fa.round {background:rgba(255, 255, 255, 0.15); font-size:1.3em; width: 40px; height: 40px; padding: 11px 0 0;  }
.fadebox_textdiv .fa.circle { clear:both; margin: 10px auto 0; }
.box .fa {display:block; margin-bottom:15px; text-align:center; }

/*-------------egna klasser---------------------*/
.spaltX2 {column-count: 2; column-gap: 50px; text-align:left !important;} /*Delar upp i 2 spalter*/
.shadow {box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3) }
.shadowborder {box-shadow: 0px 0px 0px 1px #e4e4e4;}
.pubdatum,.pubdatum:hover {color:#75A940; font-weight:400; font-size:95%; display:block; clear:both;  }
.record {border-bottom:1px solid #e4e4e4; padding: 0 0 20px; margin: 0 0 20px; }

.infoblad {display:block; padding: 12px 20px ;background: #404040; color:#fff; margin: 0 0 4px;  }
.infoblad:hover {  color:#fff;    }
.nyheter .box_textdiv p:last-of-type {margin-bottom:0; }
.nyheter h3 {font-weight:400; font-size:1.2em;  }
.nyheter h2 {padding: 0 0 20px;  }

/*---------------------------------------------------------------FONTS------------------------------------------------------------------------*/
h1, h2, h3, h4, h5, h6 {margin: 0; letter-spacing:0; line-height:1.1em; padding: 0px; font-weight:600;  }
h1{font-size: 2.2em; padding: 0px 0 20px; /*text-transform:uppercase;*/}
h2{font-size: 1.7em;}
h3{font-size: 1.3em;}
h4, h5, h6 {font-size: 1em; }

h2.highlight {font-size:4em; line-height:1.2em; padding: 20px 0 ; color:#e4e4e4; font-weight:300; }
.sida.listing h1 {padding:0 0 40px;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------STARTSIDAN various------------------------------------------------------------------------*/ 
.toptext { position:fixed; right:10px; top:95px; z-index:1;   }  
.topbild {min-height:55vh; background-position: center top; background-size:cover; position: relative; border-bottom: 10px solid #cacaca; }
 

/*----------------------Bildspel-----------------------*/ 
.slick-slider {margin:0; position: fixed; width:100%; top:80px;   }
.slick-slide {background-size:cover; background-position: center center; display:flex !important; min-height:70vh;}   
.slick {visibility: hidden; min-height:70vh; }
.slick-initialized {visibility: visible;}
.bildspel_titel {width:100%; text-align:center;  padding: 25px 0; background:rgba(0, 0, 0, 0.55);  }
.bildspel_titel  h1 {padding:0; font-size: 2.6em; color:#fff; text-shadow: 0 0 10px rgba(0, 0, 0, 0.4);   }

  
 
/*Slick arrows
.slick-prev, .slick-next {top:20vh; width:45px; height:100px; border:none; margin:0; color:transparent; position:absolute; z-index:1; padding:0;}  
.slick-prev {left:30px;} .slick-next {right:30px;}
.slick-prev:before, .slick-next:before { font-size:120px; color:rgba(255, 255, 255, 0.6); font-family: FontAwesome; }
.slick-prev:before {content:"\f104";} .slick-next:before {content:"\f105";}
.slick-prev:hover, .slick-next:hover {background:none !important;}*/

/*Slick dots
.slick-dots {margin:0; bottom: 10px;  }
.slick-dots li button {background:#000; border-radius:50%; width:14px; height:14px; opacity:0.6; transition: opacity 0.5s ease-in 0s;  }
.slick-dots > .slick-active > button {opacity:1; }
.slick-dots li button:before {color: transparent; content: "";}*/

/*Scroll effect specifik css*/
.wrapper.scroll_effect {height:calc(100vh - 80px); min-height:1px; width:100%; top: 80px;  overflow-x: hidden; overflow-y: auto; perspective: 1px; perspective-origin: 100% 50%;} 
.scroll_effect .slick, .scroll_effect .topbild_wrapper  { width: 100vw; position: absolute; top: 0; right: 0; bottom: 0;left: 0; transform: translateZ(-1px) scale(2); transform-origin: 100% 50%;} 
.scroll_layer  {position: absolute; top: 0; right: 0; bottom: 0;left: 0; transform-origin: 100% 50%; transform: translateZ(0); }  
.scroll_layer  {padding: 50vh 0 0;} .startsida .scroll_layer {padding: 70vh 0 0;}   /*sätt padding efter height på slick eller topbild*/
.scroll_layer .sida {min-height: calc(100vh - 80px - 50vh);} /*sätt efter height på topbild*/
.wrapper.scroll_effect .startsidatext, .wrapper.scroll_effect .boxar, .wrapper.scroll_effect .hoverboxar, .wrapper.scroll_effect .sida {background:#fff;} 


/*IE*/
.IE11 .wrapper.scroll_effect, .IE10 .wrapper.scroll_effect, .IE9 .wrapper.scroll_effect { perspective:none; perspective-origin: 50% 50%; }
.IE11 .scroll_effect .slick, .IE10 .scroll_effect .slick, .IE9 .scroll_effect .slick, .IE11 .scroll_effect .topbild_wrapper, 
.IE10 .scroll_effect .topbild_wrapper,.IE9 .scroll_effect .topbild_wrapper  { position: relative; transform: none; transform-origin: 50% 50%;} 
.IE11 .scroll_layer, .IE10 .scroll_layer, .IE9 .scroll_layer  {position: relative; padding:0;  transform-origin: 50% 50%; transform:none; }  







/*----------------------Startsidatext-----------------------*/
.startsidatext {border-top: 10px solid #cacaca; padding: 40px 0 20px; width:100%; position:relative; margin-top: calc(70vh + 70px); background:#fff; z-index:1;  }
.startsidatext_textdiv {text-align:center;  font-size:1.3em; line-height:1.2em; } 

/*----------------------Boxar-------------------------------*/
.boxar {padding: 20px 0 40px; }
.box {}
.box h2 {}
.box_textdiv {}

 
/*blogg*/
.categories-wrapper, .tags-wrapper {padding: 10px 0 30px 20px; margin-left: 20px; border-left: 2px solid #d0d0d0; }
.categories-wrapper a {display:block; }

 



/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*---------------------------------------------------------------GALLERI ------------------------------------------------------------------*/
.magnificgalleri, .wrapper .loggagalleri {padding: 30px 0 0; margin-top:10px; border-top: 1px solid #d0d0d0; }
.galleri_bild a {display:block; }
.galleri_bild {box-shadow: 1px 1px 0 0 #e4e4e4;} .galleri_bild:nth-child(5n + 5) {box-shadow: 0px 1px 0 0 #e4e4e4;}
.galleri_bild img {padding:20px; display: block;}
.thumbtitel {font-size:90%; padding: 0 20px 20px;}  

/*logo gallery*/
.loggagalleri .logga {min-height: 70px; } 
.loggagalleri .logga div {text-align:center; width: 100%; flex:0 0 auto;} 
.loggagalleri .logga img {height:auto; }
.standing  {width:40%; } .squarish  {width:40%; } .landscape  {width:55%; }.longlandscape {width:70%; }.extralonglandscape {width:100%; }

/*magnific styling*/ 
.mfp-bg {background: #000; opacity:0.9;}   
.mfp-arrow, .mfp-arrow:before,.mfp-arrow:after {border:none;  height:100px; color:#fff; font-size:60px; font-family: FontAwesome; opacity:0.9; }
.mfp-arrow-left:before {content:"\f104"; } .mfp-arrow-right:before {content:"\f105"; }
button.mfp-close { font-size: 3.4em; margin:0 -2px 0 0; opacity: 0.9;}
 

/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*--------------------------------------------------------------KONTAKT SAKER------------------------------------------------------------------*/
/*Kontaktbanner & Formulär*/
.boltforms-row label, .boltforms-row button {font-weight: 400; margin-bottom: 0;}
.boltforms-row input, .boltforms-row textarea {width:100%; color:#606060;  }

.kontaktbanner {background: #75a940; padding:50px 0; color:#fff; box-sizing: border-box; position:relative; z-index:1; }
.kontaktbanner h2 { padding:0px 0 10px; }
.kontaktbanner .boltforms-row {float:left; width:33.3%; padding-right:10px;}
.kontaktformular .boltforms-row, .kontaktbanner .boltforms-row:nth-child(n+4) {width:100%;}

/*Googlekarta*/
.map-canvas {height: 35vh; margin-top:2em;}
.map-canvas img {max-width: none;}

/*Sifoten*/
footer {padding: 50px 0 120px; width:100%; background:#000; position:relative; z-index:1;}
footer, footer a, footer a:hover {color:#b0b0b0; font-weight:300; }
footer h3 {padding: 0 0 5px;} 

/*intendit*/
.created-by, .created-by a  {color: #666666 !important;font-size: 0.95em;letter-spacing: 0.5pt; }
.created-by  {text-align:center; padding: 5px 0 10px; background:#050505; }
.created-by p {margin:0; }


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------------------------------------------HEADER SPECIFIC---------------------------------------------------------------*/
header {text-transform:none; width:100%; background:#fff; z-index:10; top:0; min-height:80px; position:fixed; }
body.scrolled header {}

/*Logotype*/
.logo {padding:10px 15px; box-sizing:border-box; } 
.logo img {max-height: 60px; width:auto; max-width:calc(100vw - 100px); display:block;  }
.logo a {padding:0; display:block;}  

/*Standard meny + hovers*/
nav {background:inherit; } 
header nav ul.menu > li {padding: 0 10px; margin:0; border-right:1px solid #e4e4e4;}
nav ul.menu > li:last-child {border:none; }
nav ul.menu li a { padding: 4px 5px; margin:0; letter-spacing:0; /*transition:color 0.5s ease-in 0s;*/}
nav ul.menu li a:hover, nav ul.menu li.active a {}
nav ul.menu li.dropdown > a:after { content: " \f107"; display: inline-block; font-family:FontAwesome; font-size: 1em; margin: 0 0 0 5px; vertical-align: middle;}
nav .menu .dropdown ul  {border:none; padding: 10px 0; left:20px; }
nav ul.menu > li:last-child > ul { left: auto;right: 0; text-align:right; }

/*Mobile menu - fixed header*/
@media (max-width: var(--breakpoint-menu)){
header {position:fixed; top:0; min-height:80px; box-shadow:none; flex-wrap:nowrap; border-bottom: 1px solid #e4e4e4;} /*background på header bar*/
.wrapper {margin-top:80px; } 
nav ul.menu {margin-top:1px; background:rgba(255, 255, 255, 0.9); }
nav ul.menu.show { max-height:calc(100vh - 80px); padding: 10px 0;  }  /*räknar höjd - fixed header*/
nav ul.menu li, .mobile-menu nav ul.menu li a, .mobile-menu nav .menu .dropdown ul {border:none; background:none; }
nav .menu .dropdown ul {padding:0; left:0px;} 
nav ul.menu li.dropdown > a:after {display:none; }
/*menu icon*/
nav #menu-icon {padding: 20px 20px 20px 0;}
nav #menu-icon span {border-radius:3px; border: 2px solid #000;}
}
 


/*-▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮▮-*/
/*----------------------------Allt under 1300px----------------------------*/
@media (max-width: 1300px) 
{  
/* Standard meny - tight it up*/ 
nav ul.menu li {padding:0 5px; }      
nav ul.menu li a {}    
}
   


/*----------------------------Allt under 1100px----------------------------*/
@media (max-width: var(--breakpoint-m))
{
.startsidatext_textdiv {
    font-size: 1.15em;} 
.hoverboxar {
    padding: 20px 0;}
    
.bildspel_titel h1 {
     font-size: 2.2em;}
.slidein .hoverbox_innerdiv h2 { font-size: 1.6em;}
.slidein .hoverbox_innerdiv p {font-size:0.95em;}
    
/*Containers*/
.container, .container.lrg {width: 98%;}  
header.container, header.container.lrg {width: 100%;}  
}
/*----------------------------Allt under 800px----------------------------*/
@media (max-width: var(--breakpoint-s))
{
    
.startsidatext {
    margin-top: calc(55vw + 70px);}
    
/*Fontsize*/ 
body { line-height:1.25em; font-size: 0.97em; }
    
/*Slider & topbild height*/  
.slick, .slick .slick-slide, .topbild {min-height:55vw;}
    
.scroll_layer, .startsida .scroll_layer {padding: 55vw 0 0;}  
.scroll_layer .sida {min-height:1px;} 
    
    
/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 0 1px 0 0 #e4e4e4;}
.galleri_bild:nth-child(5n+5) {box-shadow: 1px 1px 0 0 #e4e4e4;}  
    
/*Bild*/
.bild { margin: 0 0 20px 20px;  }      
   
/*Sidfot*/   
footer .s-twelve {border-bottom: 1px solid #222222;margin: 0 auto;max-width: 400px; padding: 20px 0;text-align: center; }
footer .s-twelve:last-child {border-bottom: none; }   
}


/*---------------------------MEDIA XS - upp till 550-----------------------------*/
@media (max-width: var(--breakpoint-xs))
{
    
/*blogg*/
.categories-wrapper, .tags-wrapper {padding: 10px 0 30px 0px; margin-left: 0px; border-left: none; }
 
 
    
    
    
    .toptext {position:absolute; }  
    
.bildspel_titel h1 {
     font-size: 2em;}  
    
/*sidfot*/ 
footer  {font-size: 1.1em; line-height: 1.5em; word-spacing: 2pt;}     
    
/*spalt*/    
.spaltX2 {-webkit-column-count: 1; -moz-column-count: 1; column-count: 1; -webkit-column-gap: 0px; -moz-column-gap: 0px; column-gap: 0px; text-align:none;} 
   
/*Kontaktbanner*/   
.kontaktbanner .boltforms-row {width:100%; padding-right:0px;}

/*gallerithumbs*/
.galleri_bild:nth-child(3n+3) {box-shadow: 1px 1px 0 0 #e4e4e4;} 
.galleri_bild:nth-child(2n+2) {box-shadow: 0 1px 0 0 #e4e4e4;} 
.galleri_bild img {padding: 13px;}    
    
/*map*/
.map-canvas {width:100%; max-height:200px; }
    
    
    
    
/*table varje cell under varann med border efter varje rad 
table tr td {display: list-item; list-style: none; padding:0; }
table tr td:first-child {padding: 10px 0 0;}
table tr td:last-child {padding: 0 0 10px;}*/     
}

@media (max-width: 350px)
{
.bildspel_titel h1 {
     font-size: 1.8em;}    
    
/*bild*/
.bild {width:100%; max-width:100%; margin: 0 0 10px; float:none; }     
.bild img {width:100%; height:auto;}    
}

/*-------------------------------------------------------------------END MEDIA QUERYS--------------------------------------------------------------------*/




 

/* --------------------------------------------------------Sliding border under text--------------------------------------------------------- 
.box h2 {padding-bottom: 35px; position: relative; text-align: center;}
.box h2:after {content:""; position:absolute; background:#404040; height:3px; width:20px; margin: 45px 0 0; left:50%; transition: width 0.5s ease-in 0s; transition: margin 0.5s ease-in 0s; }
.box:hover h2:after { width:150px;  margin:50px 0 0 -75px; }*/

/* ------------------------------------------------------------Tables--------------------------------------------------------------------------
table { margin:30px 0; font-size:95%; }
table tr {width:100%; border-bottom: 1px solid #e4e4e4;}
table tr td {margin: 0; padding:5px 30px 5px 0; vertical-align: top; border:none; }
table tr td:last-child {padding:5px 0px 5px 0;}

/* ------------------------------------------------------------Sökning--------------------------------------------------------------------------
.searchbox {}
#searchform {margin:0; }
#searchform input {margin:0; height: 23px; padding: 3px 10px; color:#959595; display: inline-block; }
.search {float: none;padding: 0;}*/

/* ------------------------------------------------------------Egen menu -icon-----------------------------------------------------------------
#menu-icon span {border:none; }
#menu-icon  {background-image: url("/theme/intendit/icons/mobile-menu-icon.png"); background-position: center right; background-repeat: no-repeat;
background-size: 100% auto; height: 30px; width: 40px;}*/


/*------------------------------------------------------Make FB widget responsive-------------------------------------------------------*/
.fb_iframe_widget, .fb_iframe_widget span, .fb_iframe_widget iframe[style], .twitter-timeline {width: 100% !important;}
